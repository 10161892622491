import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";

function NavigationBar() {
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  // Met à jour la valeur isMobile en cas de redimensionnement de l'écran
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 992);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Navbar
      expand="lg"
      className="bg-light shadow-sm fixed-top"
      expanded={expanded}
    >
      <Container>
        {/* Logo à gauche */}
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="Pack Mobilier Logo" height="40" className="d-inline-block align-top" />
        </Navbar.Brand>

        {/* Bouton pour mobile */}
        <Navbar.Toggle
          aria-controls="navbar-nav"
          onClick={() => setExpanded(expanded ? false : true)}
        />

        <Navbar.Collapse
          id="navbar-nav"
          onMouseLeave={() => isMobile && setExpanded(false)} // Ferme le menu seulement en mobile
        >
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">
              <i className="bi bi-house-door me-2"></i>A propos
            </Nav.Link>
            <Nav.Link as={Link} to="/packs">
              <i className="bi bi-box-seam me-2"></i>Nos Packs
            </Nav.Link>
            <Nav.Link as={Link} to="/produits">
              <i className="bi bi-bag me-2"></i>Nos Produits
            </Nav.Link>
            <Nav.Link as={Link} to="/faq">
              <i className="bi bi-question-circle me-2"></i>FAQ
            </Nav.Link>
            <Nav.Link as={Link} to="/contact">
              <i className="bi bi-envelope me-2"></i>Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
