import React, { useState } from "react";
import emailjs from "emailjs-com";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Contact.css";
import Navbar from "../components/Navbar";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    status: "",
    phone: "",
    pack: "",
    duration: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let newErrors = {};
    Object.keys(formData).forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "Ce champ est obligatoire.";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const serviceID = "service_yv5wjgj";
    const templateID = "template_03u09zs";
    const userID = "6dC8iXbPE0a25byxi";

    emailjs
      .send(
        serviceID,
        templateID,
        {
          name: formData.name,
          email: formData.email,
          status: formData.status,
          phone: formData.phone,
          pack: formData.pack,
          duration: formData.duration,
          message: formData.message,
          to_email: "contact@pack-mobilier.com",
        },
        userID
      )
      .then(
        (response) => {
          console.log("Email envoyé avec succès !", response.status, response.text);
          setIsSubmitted(true);
          setFormData({
            name: "",
            email: "",
            status: "",
            phone: "",
            pack: "",
            duration: "",
            message: "",
          });
        },
        (error) => {
          console.error("Échec de l'envoi de l'email", error);
        }
      );
  };

  return (
    <>
      <Navbar />

      <div className="container contact-container">
        <h2 className="contact-container-title text-center">CONTACTEZ NOUS</h2>
        {isSubmitted && <p className="success-message text-center">Votre message a été envoyé avec succès !</p>}
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <label>Nom & Prénom: *</label>
              <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} />
              {errors.name && <small className="text-danger">{errors.name}</small>}
            </div>
            <div className="col-md-6">
              <label>Adresse E-mail: *</label>
              <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange} />
              {errors.email && <small className="text-danger">{errors.email}</small>}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>Votre statut: *</label>
              <select name="status" className="form-control" value={formData.status} onChange={handleChange}>
                <option value="">Choisissez une option</option>
                <option value="Étudiant">Étudiant</option>
                <option value="Stagiaire">Stagiaire</option>
                <option value="Jeune Pro">Jeune Pro</option>
                <option value="PVTiste">PVTiste</option>
              </select>
              {errors.status && <small className="text-danger">{errors.status}</small>}
            </div>
            <div className="col-md-6">
              <label>Numéro de téléphone *</label>
              <input type="tel" name="phone" className="form-control" value={formData.phone} onChange={handleChange} />
              {errors.phone && <small className="text-danger">{errors.phone}</small>}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>Choix du Pack: *</label>
              <select name="pack" className="form-control" value={formData.pack} onChange={handleChange}>
                <option value="">Choisissez une option</option>
                <option value="Pack Basique">Pack Chambre</option>
                <option value="Pack Standard">Pack Studio</option>
                <option value="Pack Premium">Pack Appartement</option>
              </select>
              {errors.pack && <small className="text-danger">{errors.pack}</small>}
            </div>
            <div className="col-md-6">
              <label>Durée de location: *</label>
              <select name="duration" className="form-control" value={formData.duration} onChange={handleChange}>
                <option value="">Choisissez une option</option>
                <option value="1 mois">4/5 mois</option>
                <option value="3 mois">6/7 mois</option>
                <option value="6 mois">8 mois & +</option>
              </select>
              {errors.duration && <small className="text-danger">{errors.duration}</small>}
            </div>
          </div>

          <div className="form-group">
            <label>Message: *</label>
            <textarea name="message" className="form-control" rows="5" value={formData.message} onChange={handleChange}></textarea>
            {errors.message && <small className="text-danger">{errors.message}</small>}
          </div>

          <button type="submit" className="btn btn-dark btn-block mt-3">Envoyer</button>
        </form>
      </div>

      <div className="container-fluid full-width-section mt-4">
        <div className="row no-gutters align-items-stretch">
          <div className="col-md-3 horaires-section d-flex flex-column justify-content-center text-center">
            <h3 className="fw-bold">Horaire d'ouverture</h3>
            <p>Lun - Ven : 10h - 18h</p>
            <p>Samedi : 10h - 14h</p>
            <p>Dimanche : fermé</p>
          </div>
          <div className="col-md-9 google-map-container">
          <iframe
  title="Google Maps Location"
  className="google-map"
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.6478316257096!2d-73.56980638888888!3d45.5031825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91a4000000001%3A0x4d4c28f7d5c09a7d!2sMontr%C3%A9al%2C%20QC%2C%20Canada!5e0!3m2!1sfr!2sfr!4v1707853151636!5m2!1sfr!2sfr"
  allowFullScreen=""
  loading="lazy"
></iframe>

          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
