import React, { useState } from 'react';
import '../styles/FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
        question: "Quel est le moyen de communiquer avec vous ?",
        answer: "Il est préférable de nous envoyer un courriel en passant par la section contact de notre site web en donnant le plus d’informations possible (le pack choisi, la durée de la location, votre statut au Canada, etc). Nous nous engageons à vous faire un devis dans un délai de 24 heures."
    },
    {
        question: "A qui s’adresse la location de meubles avec Pack Mobilier ?",
        answer: "Notre service de location est offert aux gens de passage à Montréal, comme des étudiants, stagiaires, pvtistes, jeunes professionnels, etc. Nous demandons des preuves que vous êtes bien de passage à Montréal (preuve scolaire, permis d’études, etc) et nous nous réservons le droit de refuser certaines demandes."
    },
    {
        question: "Quelle est la durée minimale de location ?",
        answer: "Nous acceptons les contrats de 5 mois et plus. Dans certains cas, il arrive que nous acceptions 4 mois (pour une session universitaire) mais en dessous de cela, nous ne donnerons pas suite à votre demande."
    },
    {
        question: "Quel est le nombre d’articles minimum que je peux commander ?",
        answer: "5 articles minimum doivent être commandés pour un pack sur mesure."
    },
    {
        question: "Quel est le délai de livraison ?",
        answer: "Une fois que vous acceptez le devis de votre pack choisi et que vous envoyez une copie ou un scan des papiers demandés dans votre contrat, nous planifierons une livraison avec vous, selon vos disponibilités. Nous pouvons livrer dans un délai de 48 à 72 heures après l’acceptation du contrat."
    },
    {
        question: "Puis-je choisir mes meubles ?",
        answer: "Notre entrepôt n’est pas ouvert au public et nos meubles sont les mêmes que sur notre site web. Par contre, il est possible de choisir vos meubles, selon le stock disponible en entrepôt, quelques jours avant votre livraison. Nous vous envoyons les meubles disponibles par courriel et vous pouvez faire votre choix."
    },
    {
        question: "Quelle est la région desservie par Pack Mobilier ?",
        answer: "Notre service est offert sur l'île de Montréal, Longueuil et Laval."
    },
    {
        question: "Quels sont vos modes de paiement ?",
        answer: "Nous acceptons les paiements par virement Interac et argent comptant."
    },
    {
        question: "Que dois-je prévoir pour le jour de la livraison ?",
        answer: "Le jour de la livraison, vous devez avoir en main les papiers demandés dans le contrat, votre première mensualité, les frais de service et le dépôt. Nous signerons avec vous le contrat le jour de la livraison."
    },
    {
        question: "Comment fonctionnent les paiements ?",
        answer: "Le jour de la livraison, vous devez payer le montant communiqué par argent comptant ou virement Interac du mois en cours. Par la suite, tous les paiements seront effectués le 1er de chaque mois par virement Interac."
    },
    {
        question: "Dois-je payer des taxes ?",
        answer: "Non, le prix de nos packs est taxes comprises."
    },
    {
        question: "Pourquoi donner une caution ?",
        answer: "La caution sert d’assurance envers les meubles loués en cas de dégradation. Elle vous sera remise à la fin de votre contrat après inspection des meubles. En cas de bris du matériel, il se pourrait que l’on retienne un certain montant sur votre caution ou la caution au complet."
    },
    {
        question: "Je déménage, qu’arrive-t-il avec le transport de mes meubles ?",
        answer: "Il est de votre devoir de nous contacter afin de nous mentionner votre déménagement. Seul Pack Mobilier peut transporter les meubles de votre logement à un autre car ils sont notre propriété."
    },
    {
        question: "Quel est mon engagement envers Pack Mobilier lors de ma durée de location ?",
        answer: "Tout d’abord, garder votre mobilier propre et en bon état. Également, de nous fournir un numéro de téléphone valide, un courriel valide, de répondre aux messages envoyés dans un délai raisonnable. Si pour une raison personnelle, vous devez quitter le pays avant la fin de votre contrat, vous devez nous aviser à l’avance de votre départ. Si vous prévoyez louer votre chambre avec nos meubles à une autre personne, vous devez nous en aviser pour que l’on puisse vérifier les informations du nouveau locataire et ainsi faire un nouveau contrat avec celui-ci."
    },
    {
        question: "Je suis dans l’impossibilité de faire mon paiement à la date prévue, quelle est la solution ?",
        answer: "Tout d’abord, nous aviser. Nous préférons la carte de l’honnêteté que de devoir vous chercher. Il est possible de prendre une entente avec vous, selon les raisons du retard de paiement. Nous avons une certaine flexibilité pour vous accommoder. Si nous avons aucune nouvelle de vous après une semaine de retard de paiement ou que les raisons du retard de paiement ne sont pas valables, nous nous réservons le droit de récupérer nos meubles et ce, sans préavis."
    }
];

  return (
      
      <div>

        {/* Bannière statique sous le Navbar */}
            <section 
                className="faqbanner"
                style={{
                background: `url(${process.env.PUBLIC_URL + '../faq/faq-bann.jpg'}) center/cover no-repeat`,
                height: "600px", // ajustez la hauteur selon vos besoins
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textShadow: "1px 1px 3px rgba(59, 59, 59, 0.7)" // pour améliorer la lisibilité sur fond d'image
                }}
                >
                <h1 className="banner-title">FAQ ?</h1>
                <h2 className="banner-subtitle">Les réponses à vos questions</h2>
            </section>

            {/* Section 2: Contenu des questions et réponses */}
          <section className="faq-content">
        {faqData.map((item, index) => (
          <div 
            key={index} 
            className={`faq-card ${activeIndex === index ? 'active' : ''}`} 
            onClick={() => toggleAnswer(index)}
          >
            <h3>{item.question}</h3>
            <div className="faq-answer">
              {activeIndex === index && <p>{item.answer}</p>}
            </div>
          </div>
        ))}
      </section>
      </div>
  );
};

export default FAQ;
