import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "react-photo-album/styles.css";
import "yet-another-react-lightbox/styles.css";
import "../styles/Produits.css";

const produits = [
  { nom: "Lits & Matelas",     dossier: "lits_matelas",     image: "/products/lits_matelas.jpg" },
  { nom: "Tables de Chevets",  dossier: "tables_chevets",    image: "/products/tables_chevets.jpg" },
  { nom: "Bureaux & Chaises",  dossier: "bureaux_chaises",   image: "/products/bureaux_chaises.jpg" },
  { nom: "Canapés & Canapé-Lits", dossier: "canapes",        image: "/products/canapes.jpg" },
  { nom: "Tables-basses",      dossier: "tables_basses",     image: "/products/tables_basses.jpg" },
  { nom: "Tables & chaises",   dossier: "tables_chaises",    image: "/products/tables_chaises.jpg" },
  { nom: "Autres",             dossier: "autres",            image: "/products/autres.jpg" },
];

const Produits = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(null);

  useEffect(() => {
    if (selectedProduct) {
      const loadImages = async () => {
        try {
          const response = await fetch(`/albums/${selectedProduct}/images.json`);
          const imageFiles = await response.json();
          
          // Ratio 4:3 pour de petites vignettes dans la galerie
          const photosArray = imageFiles.map((file) => ({
            src: `/albums/${selectedProduct}/${file}`,
            width: 4,
            height: 3,
          }));

          setPhotos(photosArray);
        } catch (error) {
          console.error("Erreur de chargement des images :", error);
          setPhotos([]);
        }
      };
      loadImages();
    }
  }, [selectedProduct]);

  return (
    <div>
      <Navbar />

      <div className="container text-center products-container">
        <h2 className="products-title">Produits en location</h2>

        {selectedProduct ? (
          <div>
            {/* Titre + bouton "Retour" */}
            <div className="d-flex justify-content-between align-items-center products-header" >
              <h3>{produits.find(prod => prod.dossier === selectedProduct)?.nom}</h3>
              <button
                className="btn btn-primary"
                onClick={() => setSelectedProduct(null)}
              >
                Retour
              </button>
            </div>

            {/* On place la galerie dans une row -> col-12 */}
            <div className="row mt-3 mb-5">
              <div className="col-12">
                <PhotoAlbum
                  layout="columns"
                  photos={photos}
                  columns={2}       // 2 colonnes
                  spacing={10}      // Espace entre colonnes
                  onClick={({ index }) => setLightboxIndex(index)}
                />
              </div>
            </div>

            {/* Lightbox sans ratio, pour afficher l'image grande adaptée à l'écran */}
            <Lightbox
              open={lightboxIndex !== null}
              close={() => setLightboxIndex(null)}
              slides={photos.map((p) => ({ src: p.src }))}
            />
          </div>
        ) : (
          <div className="row">
            {produits.map((prod, index) => (
              <div
                key={index}
                className="col-md-3 text-center"
                onClick={() => setSelectedProduct(prod.dossier)}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={prod.image}
                  alt={prod.nom}
                  className="img-fluid rounded"
                />
                <p>{prod.nom}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      <Footer/>
    </div>
  );
};

export default Produits;