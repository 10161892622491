import React from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../styles/Packs.css';

function Packs() {
  const packs = [
    {
      title: "Pack Chambre",
      subtitle: "(Colocation)",
      icon: "bi-house-door",
      image: "../packs/pack-chambre.jpg",
      pricing: [
        ["4/5 mois", "90$/mois"],
        ["6/7 mois", "85$/mois"],
        ["8 mois et +", "80$/mois"]
      ]
    },
    {
      title: "Pack Studio",
      subtitle: "(1 1/2 ou 2 1/2)",
      icon: "bi-building",
      image: "../packs/pack-studio.jpg",
      pricing: [
        ["4/5 mois", "110$/mois"],
        ["6/7 mois", "100$/mois"],
        ["8 mois et +", "90$/mois"]
      ]
    },
    {
      title: "Pack Appartement",
      subtitle: "(3 1/2 ou 4 1/2)",
      icon: "bi-building-check",
      image: "../packs/pack-appartement.jpg",
      pricing: [
        ["4/5 mois", "140$/mois"],
        ["6/7 mois", "130$/mois"],
        ["8 mois et +", "120$/mois"]
      ]
    }
  ];

  return (
    <div>
      {/* Bannière statique sous le Navbar */}
      <section 
        className="banner"
        style={{
        background: `url(${process.env.PUBLIC_URL + '../packs/packs-bann.jpg'}) center/cover no-repeat`,
        height: "600px", // ajustez la hauteur selon vos besoins
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff", // couleur du texte, ajustez si besoin
        textShadow: "1px 1px 3px rgba(0,0,0,0.7)" // pour améliorer la lisibilité sur fond d'image
        }}
>
        <h1 className="banner-title">Nos Packs</h1>
        <h2 className="banner-subtitle">Retrouvez en détails nos packs</h2>
      </section>

      {/* Section Packs */}
      <section id="packs">
        <div className="container text-center">
          <p >
            Nos forfaits de location de meubles sont proposés selon des packs.<br/>
            Les meubles peuvent être loués de 4 à 12 mois selon vos besoins.<br/>
            Nos solutions sont en fonction de votre type de logement avec un service complet :<br/>
            livraison, assemblage, installation et récupération au terme du contrat. <br/>
            Une facilité de la vie, quelque soit votre situation (étudiants, stagiaires…).
          </p>
          

          <div className="row">
            {packs.map((pack, index) => (
              <div className="col-md-4" key={index}>
                <div className="pack-card">
                  <div className="pack-image">
                    <img src={pack.image} alt={pack.title} className="img-fluid" />
                  </div>
                  <div className="pack-pricing">
                    <h4>PRIX & DURÉE :</h4>
                    <table className="table table-bordered">
                      <tbody>
                        {pack.pricing.map((price, i) => (
                          <tr key={i}>
                            <td>{price[0]}</td>
                            <td>{price[1]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Link to="/contact" className="btn btn-primary mt-2">
                      Nous contacter
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Packs;
