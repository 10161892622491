// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Home.css'; // Import du fichier CSS

// Import des images
import engagementImage from '../assets/images/engagement.jpg';
import img1 from '../assets/images/img1.jpg';
import img2 from '../assets/images/img2.jpg';
import img3 from '../assets/images/img3.jpg';
import img4 from '../assets/images/img4.jpg';
import img5 from '../assets/images/img5.jpg';
import referent1 from '../assets/images/referent1.jpg';
import referent2 from '../assets/images/referent2.jpg';
import referent3 from '../assets/images/referent3.jpg';
import referent4 from '../assets/images/referent4.jpg';

function Home() {
  const images = [img1, img2, img3, img4, img5];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div>
      {/* --- Section HERO avec slideshow et arrière-plan semi-transparent --- */}
      <section
        className="d-flex align-items-center justify-content-center text-center hero-section"
        style={{
          background: `url(${images[currentIndex]}) center/cover no-repeat`,
          transition: 'background-image 1s ease-in-out',
        }}
        id="home"
      >
        <div className="hero-text-container">
          <h1 className="hero-title">Pack Mobilier</h1>
          <p className="hero-subtitle">Le spécialiste de la location des meubles</p>
        </div>
      </section>

      {/* --- Section Le Concept --- */}
      <br></br>
      <section className="py-5" id="concept">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-md-5 concept-background"></div>

            <div className="col-md-6">
              <h2 className="section-title">Le Concept</h2>
              <p>
                Pack Mobilier réinvente une nouvelle manière de consommer son mobilier
                en vous louant des meubles avec plusieurs avantages. Louez des meubles
                et faites de l’économie, une économie qui peut s’étendre jusqu’à <strong>40%</strong>
                de la valeur d’achat sur certains packs choisis.
              </p>
              <p>
                Notre offre de location de meubles est sous forme de packs, vous permettant
                de choisir directement un pack clé en main pour vous installer à votre logement
                ou adapter votre commande selon votre besoin.
              </p>
              <p>
                Plus qu’un service de location de meubles, nous nous occupons de votre installation,
                un service complet offert : <strong>livraison</strong> de votre pack à votre logement, 
                <strong>assemblage</strong> de vos meubles et <strong>récupération</strong> des meubles
                à la fin du contrat.
              </p>
              <p>
                Pack Mobilier propose un service de location de meubles sur
                l’<strong>Île de Montréal</strong> / <strong>Longueuil</strong> / <strong>Laval</strong>.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* --- Section À qui s'adresse le concept ? --- */}
<section className="py-5 bg-light" id="packs">
  <div className="container text-center">
    <h2 className="section-title">À qui s’adresse le concept ?</h2>
    <p className="section-subtitle">
      Notre service de location de meubles est offert à des gens de passage à Montréal.
    </p>

    <div className="row equal-height justify-content-center">
      
      {/* Étudiants */}
      <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="concept-card">
          <h5>ÉTUDIANTS</h5>
          <i className="bi bi-mortarboard-fill concept-icon"></i>
          <p>Solution idéale pour la vie universitaire.</p>
        </div>
      </div>

      {/* Stagiaires */}
      <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="concept-card">
          <h5>STAGIAIRES</h5>
          <i className="bi bi-briefcase-fill concept-icon"></i>
          <p>Vos missions en toute flexibilité.</p>
        </div>
      </div>

      {/* Jeunes Pros */}
      <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="concept-card">
          <h5>JEUNES PROS</h5>
          <i className="bi bi-people-fill concept-icon"></i>
          <p>Un coup de pouce pour démarrer votre carrière.</p>
        </div>
      </div>

      {/* PVTistes */}
      <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="concept-card">
          <h5>PVTISTES</h5>
          <i className="bi bi-airplane-fill concept-icon"></i>
          <p>Voyagez léger, on s’occupe de votre mobilier.</p>
        </div>
      </div>

    </div>
  </div>
</section>

{/* --- Section L'avantage de la location de meubles --- */}
<section className="py-5 bg-light" id="advantages">
  <div className="container">
    {/* Titre centré */}
    <h2 className="section-title text-center">L'avantage de la location de meubles :</h2>

    <div className="row align-items-center">
      
      {/* Colonne 1 : Texte */}
      <div className="col-md-6">
        <ul className="advantages-list">
          <li>Solution économique pour les gens de passage à Montréal</li>
          <li>Être dans les frais envisagés d’emménagement pour toutes sortes de logements</li>
          <li>
            Facilité de la vie : on s’occupe de livrer vos meubles à votre logement,
            installation et montage de vos meubles et la récupération des meubles à la fin du contrat
          </li>
          <li>
            Le choix de votre logement non meublé (coup de cœur) dans plusieurs quartiers de la ville
            sans sentir que vous êtes dans l’obligation de prendre un logement meublé trop loin, trop cher ou autres
          </li>
          <li>
            Une manière de payer vos meubles en mensualités avec des avantages économiques qui vous laissent avancer
            dans votre vie (achat des manuels scolaires, payer d’autres frais d’installation, etc.)
          </li>
        </ul>
      </div>

      {/* Colonne 2 : Vidéo YouTube */}
      <div className="col-md-6 text-center">
        <div className="video-container">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/irrBgH8EsH4"
            title="L'avantage de la location de meubles"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>

    </div>
  </div>
</section>

{/* --- Section Notre Engagement --- */}
<section className="py-5 bg-dark text-white" id="engagement">
  <div className="container">
    <div className="row align-items-center">
      
      {/* Colonne 1 : Image */}
      <div className="col-md-6 text-center">
        <img 
          src={engagementImage}  /* Remplacez par votre image */
          alt="Notre Engagement" 
          className="img-fluid engagement-image"
        />
      </div>

      {/* Colonne 2 : Texte */}
      <div className="col-md-6">
        <h2 className="section-title">Notre engagement :</h2>
        <ul className="engagement-list">
          <li>Fournir un matériel intact et propre.</li>
          <li>Possibilité de choisir vos meubles selon notre stock disponible.</li>
          <li>Les prix imbattables de nos forfaits.</li>
          <li>Transporter vos meubles du début à la fin de votre contrat.</li>
        </ul>
      </div>

    </div>
  </div>
</section>

 {/* --- Nouvelle Section Référents --- */}
 <section className="py-5 bg-white" id="referents">
        <div className="container text-center">
          <h2 className="section-title">Référents :</h2>
          <div className="row justify-content-center">
            {/* Logos des référents */}
            <div className="col-md-3 col-sm-6 text-center">
              <img src={referent1} alt="Référent 1" className="img-fluid referent-logo" />
            </div>
            <div className="col-md-3 col-sm-6 text-center">
              <img src={referent2} alt="Référent 2" className="img-fluid referent-logo" />
            </div>
            <div className="col-md-3 col-sm-6 text-center">
              <img src={referent3} alt="Référent 3" className="img-fluid referent-logo" />
            </div>
            <div className="col-md-3 col-sm-6 text-center">
              <img src={referent4} alt="Référent 4" className="img-fluid referent-logo" />
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default Home;
