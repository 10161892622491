// src/components/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="footer-container">
      <p className="footer-text">
        © 2013-{new Date().getFullYear()} Copyright Pack Mobilier
      </p>
      <div className="social-icons">
        <a
          href="https://www.youtube.com/channel/UC5ZiEgAN-1njlUJCL5vPePA"
          target="_blank"
          rel="noreferrer"
          className="social-link"
        >
          <i className="bi bi-youtube"></i>
        </a>
        <a
          href="https://www.facebook.com/PackMobilier/"
          target="_blank"
          rel="noreferrer"
          className="social-link"
        >
          <i className="bi bi-facebook"></i>
        </a>
        <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noreferrer"
          className="social-link"
        >
          <i className="bi bi-instagram"></i>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
